import React from 'react';

class FileSeparator extends React.Component {
	render() {
		return (
			<div className="border-b border-solid border-gray-300 my-1" />
		);
	}
}

export default FileSeparator;

